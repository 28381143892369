@font-face {
  font-family: "Blue Cashews";
  src: url('./fonts/blue_cashews-webfont.woff2') format('woff2'),
       url('./fonts/blue_cashews-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.light-mode {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  border-width: 5px;
  margin: 15px;
  border-style: solid;
  border-image: linear-gradient(to right, rgb(250, 177, 225), rgb(234, 230, 167), rgb(203, 223, 189), rgb(168, 186, 188)) 1;
  transition: 1s;
  outline: white solid 15px;
}

.dark-mode {
  background-color: rgb(19, 19, 20);
  color: rgb(196, 196, 196);
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  border-width: 5px;
  margin: 15px;
  border-style: solid;
  border-image: linear-gradient(to right, rgb(250, 177, 225), rgb(234, 230, 167), rgb(203, 223, 189), rgb(168, 186, 188)) 1;
  transition: 1s;
  outline: rgb(19, 19, 20) solid 15px;
}

.light-footer {
  text-align: center;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  transition: 1s;
}

.dark-footer {
  text-align: center;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  background-color: rgb(19, 19, 20);
  color: rgb(226, 217, 200);
  transition: 1s;
}

.title {
  font-family: "Blue Cashews" !important;
  margin: 45px;
  margin-top: 43px;
}

.main-title {
  font-family: "Blue Cashews" !important;
  font-size: 450%;
  margin-top: 25px;
}

.name {
  font-family: "Blue Cashews" !important;
  font-size: 300%;
  margin-top: 70px;
  margin-bottom: 20px;
}

.role {
  font-family: "Blue Cashews" !important;
  background-image: linear-gradient(to right, rgb(250, 177, 225), rgb(234, 230, 167), rgb(203, 223, 189), rgb(168, 186, 188));
  margin-top: 5px;
  padding: 10px;
  padding-top: 15px;
  color: black;
}

.bio {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin:30px;
}

.lang {
  margin:30px;
  margin-left: 80px;
  margin-right: 80px;
  margin-bottom: 200px;
}

.header {
  text-align: left;
  padding-left: 30px;
  padding-bottom: 6px;
}

.links {
  text-align: left;
  margin-left: 30px;
}

.nav1 {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(255, 220, 243);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  transition-property: background-color, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.nav1:hover {
  background-color: rgb(250, 177, 225);
}

.nav1-active {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(250, 177, 225);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  margin-right: 20px;
  transition-property: background-color, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.nav2 {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(246, 244, 210);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  transition-property: background-color, margin-left, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.nav2:hover {
  background-color: rgb(234, 230, 167);
}

.nav2-active {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(234, 230, 167);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  margin-left: 20px;
  margin-right: 20px;
  transition-property: background-color, margin-left, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.nav3 {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(203, 223, 189);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  transition-property: background-color, margin-left, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.nav3:hover {
  background-color: rgb(153, 186, 130);
}

.nav3-active {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(153, 186, 130);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  margin-left: 20px;
  margin-right: 20px;
  transition-property: background-color, margin-left, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.nav4 {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(168, 186, 188);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  transition-property: background-color, margin-left, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.nav4:hover {
  background-color: rgb(122, 157, 161);
}

.nav4-active {
  text-decoration: none;
  color: black;
  font-family: "Blue Cashews" !important;
  background-color: rgb(122, 157, 161);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  margin-left: 20px;
  transition-property: background-color, margin-left, margin-right;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.icon {
  height: 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 400px;
  margin-top: 10px;
  transition: height 1s ease;
}

.icon:hover {
  height: 40px;
}

.project-link {
  display: table-cell;
  text-decoration: none;
  color: black;
  background-color: rgb(203, 223, 189);
  padding-left: 7px;
  padding-right: 7px;
  font-weight: bold;
  font-size: smaller;
  transition-property: background-color;
  transition-duration: 1s;
  transition-timing-function: ease;
}

.project-link:hover {
  background-color: rgb(153, 186, 130);
}

.project-title {
  font-family: "Blue Cashews" !important;
  margin-top: 8px;
  margin-bottom: 8px;
  display: inline-block;
  position: relative;
  color: rgb(153, 186, 130);
}

.project-title::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: rgb(153, 186, 130);
  transform-origin: bottom left;
  transition: transform 0.75s ease-out;
}

.project-title:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.projects {
  text-align: left;
  border: 3px solid rgb(203, 223, 189);
  margin: 10px;
  margin-bottom: 20px;
  padding: 15px;
}

.pic1 {
  height: 300px;
  margin: 50px;
}

.about {
  margin-bottom: 50px;
}

.course-title {
  font-family: "Blue Cashews" !important;
  font-size:medium;
}

.course {
  background-color: rgb(246, 244, 210);
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 1px;
  width: max-content;
  margin: auto;
  width: 50%;
  color: black;
}

.location {
  font-family: "Blue Cashews" !important;
  font-size:smaller;
}

.theme-icon {
  height: 60px;
  margin: 20px;
  float: right;
  position: sticky;
  top: 0;
  padding: 10px;
  transition: height 1s ease;
}

.theme-icon:hover {
  height: 70px;
}